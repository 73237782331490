import { useState } from "react"
import { Link } from "react-router-dom"

const PizzaDemo = () => {

    const [selectedState, setSelectedState] = useState(4)

    const [availableStates, setAvailableStates] = useState([
        {
            state: 'Activity Idle',
            id: 4,
        },
        {
            state: 'Place Pizza Order',
            id: 0,
        },
        {
            state: 'Delivery On The Way',
            id: 1,
        },
        {
            state: 'Order Complete',
            id: 2,
        },
        {
            state: 'End Activity Session',
            id: 3,
        }
    ])



    return (
        <>
            <section className="demo-card">
                <div className="demo-card-header">
                    <h2>Pizza Order Tracking Demo</h2>
                    <p>Activity Style: Activity Progress</p>
                </div>

                <div className="demo-card-content">
                    <div className="demo-rail">
                        {
                            availableStates.map((state, index) => (
                                <button
                                    key={index}
                                    className={`demo-rail-item ${selectedState === state.id ? 'active' : ''}`}
                                    onClick={() => {
                                        if (state.id === 3) {
                                            setSelectedState(4)
                                            alert("Activity Ended")
                                        }
                                        else {
                                            setSelectedState(state.id)
                                        }
                                    }}
                                >
                                    {state.state}
                                </button>
                            ))
                        }
                    </div>

                    <div className="demo-button">
                        <iframe src={process.env.NODE_COMPILE === 'split' ? 'http://localhost:8080/live-activity?liveWebActivityId=aaa' : '/live-activity?liveWebActivityId=aaa'}></iframe>
                    </div>
                </div>

            </section>
        </>
    )
}

export default PizzaDemo