import { useState } from "react"
import PizzaDemo from "./components/pizzaDemo"

const Demo = () => {



    return (
        <>
            <nav className="demo-nav">
                <div className="grouped-logo">
                    <img src="assets/webactivitylogo.png" alt="logo" />
                    <div className="grouped-divider"></div>
                    <h1>Demo</h1>
                </div>
            </nav>
            <div className="demo-card-container">
                <PizzaDemo />
            </div>
        </>
    )
}

export default Demo