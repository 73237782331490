import { useEffect } from "react";

const Test = () => {
    
    useEffect(() => {
        window.addEventListener('receiveFrom_LiveWebActivity', (event) => {
            let details = event.detail
            console.log(details)
            window.sendTo_LiveWebActivity({requestId: details.requestId, data: [details.data, "more Data"]})

        })
    }, [])
    return (
        <>
        </>
    )
}

export default Test;
