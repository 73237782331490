import { useState } from "react"
import { Link } from "react-router-dom"
import { useEffect } from "react";

const ActivityLauncher = () => {




    return (
        <>
            <div className="activity-launcher">
                <div className="activity-launcher-content">
                    <section >
                        <div className="activity-info-container">
                            <div className="activity-info">
                                <img src="/assets/dominoes.png" alt="logo" />
                                <div className="activity-info-text">
                                    <h2>Pizza Order Tracking</h2>
                                    <a href="https://www.dominos.com/en/">https://dominos.com</a>
                                </div>
                            </div>
                            <span>User ID: 83429791501</span>
                        </div>
                    </section>

                    <section>
                        <div className="qr-code-container">
                            <img src="/assets/qr-test.png" alt="qr-code" />
                            <div className="qr-code-text">
                                <span>To Start Live Activity</span>
                                <h3>Scan with iPhone or iPad</h3>
                            </div>

                        </div>
                        <div className="grouped-logo smaller-grouped-logo">
                            <img className="appclip-logo" src="/assets/appcliplogo.png" />
                            <div className="grouped-divider"></div>

                            <img src="/assets/webactivitylogo.png" alt="logo" />
                        </div>
                    </section>
                </div>
            </div>
        </>
    )
}

export default ActivityLauncher